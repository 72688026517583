import { Link } from "@remix-run/react";
import { FilledLinkWithIcon } from "./FilledLinkWithIcon";
import { H4 } from "../H4";

export const FullWidthImage = ({
  src,
  title,
  to,
}: {
  src: string;
  title: string;
  to: string;
}) => {
  return (
    <div className="group relative overflow-hidden md:h-[660px]">
      <div className="visible absolute left-0 right-0 top-6 mx-auto flex w-[200px] flex-row items-center justify-center group-hover:visible">
        <H4>{title}</H4>
      </div>
      <div className="visible absolute left-0 right-0 top-[50%] mx-auto flex  flex-row items-center justify-center group-hover:visible">
        <FilledLinkWithIcon
          to={to}
          title="Kaupa"
          textColor="black"
          bgColor="#E3DACC"
        />
      </div>
      <Link to={to} prefetch="viewport">
        <img src={src} alt={title} className="w-full" loading="lazy" />
      </Link>
    </div>
  );
};
